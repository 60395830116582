"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMountedRef = useMountedRef;
var react_1 = require("react");
function useMountedRef() {
    var mounted = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(function () {
        mounted.current = true;
        return function () {
            mounted.current = false;
        };
    }, []);
    return mounted;
}
